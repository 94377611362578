export enum City {
  LimsaLominsa = 1,
  Gridania,
  Uldah,
  Ishgard,
  Kugane = 7,
  Crystarium = 10,
  OldSharlayan = 12,
  Tuliyollal = 14,
}
